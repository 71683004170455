import { SupportedChainId as NetworkId } from './chain';

const MAINNET_ADDRESSES = {
  loans: 'cx66d4d90f5f113eba575bf793570135f9b10cece1',
  staking: 'cx43e2eec79eb76293c298f2b17aec06097be606e0',
  dividends: 'cx203d9cd2a669be67177e997b8948ce2c35caffae',
  reserve: 'cxf58b9a1898998a31be7f1d99276204a3333ac9b3',
  daofund: 'cx835b300dcfe01f0bdb794e134a0c5628384f4367',
  rewards: 'cx10d59e8103ab44635190bd4139dbfd682fa2d07e',
  dex: 'cxa0af3165c08318e988cb30993b3048335b94af6c',
  governance: 'cx44250a12074799e26fdeee75648ae47e2cc84219',
  band: 'cxe647e0af68a4661566f5e9861ad4ac854de808a2',
  sicx: 'cx2609b924e33ef00b648a409245c7ea394c467824',
  bnusd: 'cx88fd7df7ddff82f7cc735c871dc519838cb235bb',
  baln: 'cxf61cd5a45dc9f91c15aa65831a30a90d59a09619',
  omm: 'cx1a29259a59f463a67bb2ef84398b30ca56b5830a',
  iusdc: 'cxae3034235540b924dfcc1b45836c293dcc82bfb7',
  iusdt: 'cx3a36ea1f6b9aa3d2dd9cb68e8987bcc3aabaaa88',
  usds: 'cxbb2871f468a3008f80b08fdde5b8b951583acf06',
  cft: 'cx2e6d0fc0eca04965d06038c8406093337f085fcf',
  metx: 'cx369a5f4ce4f4648dfc96ba0c8229be0693b4eca2',
  bwt: 'cxcfe9d1f83fa871e903008471cca786662437e58d',
  airdrip: 'cxaf244cf3c7164fe6f996f398a9d99c4d4a85cf15',
  rebalancing: 'cx40d59439571299bca40362db2a7d8cae5b0b30b0',
};

const YEOUIDO_ADDRESSES = {
  loans: 'cx3259f3ff9a51ca3bf170d4ff9104cf4af126ca1c',
  staking: 'cx9d829396d887f9292d8af488fab78ad24ab6b99a',
  dividends: 'cx5b996d251009340f7c312b9df5c44f0f39a20a91',
  reserve: 'cx1754666c6779dc5e495a462144dd15e4a68fe776',
  daofund: 'cx430955c5a5e2a6e48c1f2aaa7258dc4c84222247',
  rewards: 'cx893fccdd0881d8e2bd2625f711b38e06848ecb89',
  dex: 'cx399dea56cf199b1c9e43bead0f6a284bdecfbf62',
  governance: 'cx483630769b61b76387d6ed90c911c16da546784f',
  band: 'cx61a36e5d10412e03c907a507d1e8c6c3856d9964',
  sicx: 'cxae6334850f13dfd8b50f8544d5acb126bb8ef82d',
  bnusd: 'cxc48c9c81ceef04445c961c5cc8ff056d733dfe3a',
  baln: 'cx36169736b39f59bf19e8950f6c8fa4bfa18b710a',
  omm: 'cx05515d126a47a98c682fa86992329e6c2ec70503',
  iusdc: 'cx65f639254090820361da483df233f6d0e69af9b7',
  iusdt: '',
  usds: 'cxc0666df567a6e0b49342648e98ccbe5362b264ea',
  cft: 'cxf7313d7fd611c99b8db29e298699be4b1fd86661',
  metx: '',
  bwt: 'cx5d886977b7d24b9f73a460c9ca2d43847997c285',
  airdrip: 'cx8ed4fbee9d6497f91ea90933db288ff4b43e54ba',
  rebalancing: 'cx2e3398dfce78a3c83de8a41d7c5f4aa40d3a4f30',
};

const SEJONG_ADDRESSES = {
  loans: 'cxa8e5f6263b8403b24c935ee255e06be84cb45195',
  staking: 'cx5ce1ed484edbf4332001ca40e5760acb64444a46',
  dividends: 'cxbde2a33bfadfad5703f076ec554453fcf21abc78',
  reserve: 'cxb843d3a4935ca27911d3c1a2112095f512ff9d56',
  daofund: 'cxea58bbaabbad19f2f75a3aebcb97b088e3cbc9e9',
  rewards: 'cx9f782c71811566bff738263931b01bf6844ab8e3',
  dex: 'cx9604fc2b833969260aea27373700e1547b69d34a',
  governance: 'cxdbe2036fd326eb04d9f1f336326eefb6ea558289',
  band: 'cx0999df3af49a227fc8799f0d78d19cd8fe4bb76d',
  sicx: 'cxc79530e0e4081e2e898bac7baa0075fcf71e55c1',
  bnusd: 'cx8c2cf0096636feb17bae2bb018b26dc87afb7bcf',
  baln: 'cxeed0ccc4ee5781abf65208178572e39fd6e49f53',
  omm: '',
  iusdc: '',
  iusdt: '',
  usds: '',
  cft: '',
  metx: '',
  bwt: 'cxa2afb37647ae91ca6fbf35141b2ef2ac7105720a',
  airdrip: '',
  rebalancing: 'cxb5e84abcd8ab014ca0a695fa5b3f23f7ff3ac138',
};

const addresses = {
  [NetworkId.MAINNET]: MAINNET_ADDRESSES,
  [NetworkId.YEOUIDO]: YEOUIDO_ADDRESSES,
  [NetworkId.SEJONG]: SEJONG_ADDRESSES,
};

export default addresses;
